import "@fontsource/dm-sans"
import "@fontsource/dm-sans/500.css"
import "@fontsource/dm-sans/700.css"
import "@fontsource/dm-mono"
import "@fontsource/dm-mono/500.css"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
const React = require("react")


export const onRouteUpdate = () => {
    if (typeof window !== `undefined`) { window.scrollTo(0, 0)}
}
  export const shouldUpdateScroll = args => {
    
    return false;
 };